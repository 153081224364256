import React, { useState } from 'react';
import withErrorBoundary from '../../../utils/HOCs/withErrorBoundary';
import { withSitecoreContext, Text } from '@sitecore-jss/sitecore-jss-react';
import { withNamespaces } from 'react-i18next';
import Tabs from '../../../components/molecules/Tabs';
import Typography from '../../../components/atoms/Typography';
import useStyles from './style';
import TabInner from './TabInner';
import Grid from '../../../components/molecules/Grid';
import Picture from '../../../components/atoms/Picture';
import aspectRatios from '../../../utils/AspectRatios';
import { getUId } from '../../../../utils/utility';

const RegionsMap = (data) => {
  const classes = useStyles();
  const { heading } = data.fields;
  const tabs = data.fields.tabs;
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const uid = getUId(data)
  const backgroundDesktopImage = tabs && tabs[activeTabIndex]?.fields.backgroundImage;
  const backgroundMobileImage = tabs && tabs[activeTabIndex]?.fields.backgroundMobileImage;

  const tabData = (data, index) => (
    <TabInner data={data} index={index} />
  );

  const createTabs = tabs && tabs.map((tab, index) => {
    const { tabName } = tab.fields;

    return {
      data: tabData(tab.fields || {}, index),
      label: tabName && tabName.value,
    };
  });

  const onTabChange = (value) => {
    setActiveTabIndex(value);
  };

  return (
    <section className={classes.root} data-locator={data.componentName || 'RegionsMap'} uid={uid}>
      <Picture
        media={{
          image: backgroundDesktopImage,
          mobileImage: backgroundMobileImage,
          aspectRatio: aspectRatios['5x4-m'],
        }}
        className={classes.backgroundImage}
        isParalax={true}
      />
      <Grid className={classes.container}>
        {heading && heading.value && (
          <Typography
            variant="h5Bold"
            component="h2"
            gutterBottom={false}
            className={classes.heading}
          >
            <Text field={heading} />
          </Typography>
        )}
        <Tabs
          tabs={createTabs}
          className="pills-tabs-container"
          onTabChange={onTabChange}
        />
      </Grid>
    </section>
  )
}

export default withErrorBoundary(
  withSitecoreContext()(withNamespaces()(RegionsMap))
);