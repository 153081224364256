import { makeStyles } from '@material-ui/core/styles';
import { desktopMedia, pxToRem } from '../../../../styles/themes/vad/constants';
import { fontSize } from '../../../../styles/themes/vad/font';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    overflow: 'hidden',
    minHeight: '766px',
    height: 'auto',
    display: 'flex',
    alignItems: 'flex-start',
    color: theme.palette.common.white,
    padding: '40px 0',
    [theme.breakpoints.up('lg')]: {
      alignItems: 'center',
      padding: '116px 0 44px',
      minHeight: '600px',
    },
    [theme.breakpoints.up('xl')]: {
      padding: '176px 0 124px',
      minHeight: '760px',
    },
    '&:before': {
      // background overlap linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.15) 47.02%);
      content: '""',
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      zIndex: -1,
      background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.15) 47.02%)',
    },
    '& .img-to-animate-wrapper': {
      padding: '0 0 0 0',
    },
    '& .tabPanelContainer': {
      minHeight: '320px',
    },
    '& .pills-tabs-container': {
      '& .MuiTabs-root': {
        marginTop: pxToRem(16),
        marginBottom: pxToRem(32),
        [theme.breakpoints.up('lg')]: {
          marginTop: pxToRem(36),
          marginBottom: pxToRem(24),
        },
        '& .MuiTab-root': {
          color: 'rgba(255, 255, 255, 1)',
          borderRadius: '50px',
          border: '1px solid rgba(255, 255, 255, 0.5)',
          padding: '6px 16px',
          '&.Mui-selected': {
            backgroundColor: theme.palette.common.SeaGreen,
            borderColor: theme.palette.common.SeaGreen,
          },
          '&:hover:not(.Mui-selected)': {
            color: theme.palette.common.white,
            borderColor: theme.palette.common.white,
          },
        },
      },

    },
  },
  heading: {
    width: '43%',
    display: 'flex',
    '& h2': {
      ...theme.palette.common.ellipsis(3),
    },
    [theme.breakpoints.up('md')]: {
      '& h2': {
        ...theme.palette.common.ellipsis(2),
      },
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: fontSize.s22Rem,
      lineHeight: fontSize.s32Rem,
      width: '93%',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '305px',
    },
  },
  tabTitle: {
    ...theme.palette.common.ellipsis(3),
    [theme.breakpoints.up('md')]: {
      ...theme.palette.common.ellipsis(2),
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: fontSize.s32Rem,
      lineHeight: fontSize.s40Rem,
    },
  },
  backgroundImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -2,
    objectFit: 'cover',
  },
  container: {
    width: '100%',
  },
  tabWrapper: {
    width: '100%',
  },
  tabsFlexbox: {
    flexDirection: 'column-reverse',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
    },

  },
  tabTextContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '93%',
    marginTop: pxToRem(34),
    [theme.breakpoints.up('lg')]: {
      width: '40%',
      maxWidth: 'inherit',
      marginTop: pxToRem(0),
    },
  },
  tabDescription: {
    marginTop: pxToRem(16),
    fontSize: fontSize.s16Rem,
    ...theme.palette.common.ellipsis(4),
    [theme.breakpoints.up('md')]: {
      ...theme.palette.common.ellipsis(2),
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: fontSize.s22Rem,
    },
  },
  tabMapWrapperOuter: {
    position: 'relative',
    width: '100%',
    margin: '0px 0 auto',
    [theme.breakpoints.up('lg')]: {
      width: '55%',
      margin: '-123px 0 auto',
    },
    [theme.breakpoints.up('xl')]: {
      marginTop: '-163px',
    },
  },

  tabMapWrapper: {
    position: 'relative',
    marginTop: '0%',
    width: '327px',
    maxWidth: '100%',
    margin: '0 auto',
    [theme.breakpoints.up('lg')]: {
      width: 'auto',
      position: 'absolute',
      top: '0',
      left: '50%',
      transform: 'translateX(-50%)',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    '& .mapContainer': {
      position: 'relative',
      paddingTop: '60%',
      width: '327px',
      maxWidth: '100%',
      height: 'auto',
      [theme.breakpoints.up('lg')]: {
        width: '753px',
        maxWidth: '100%',
      },
    },

    '& svg': {
      maxWidth: '100%',
      maxHeight: '197px',
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      [theme.breakpoints.up('lg')]: {
        maxHeight: '452px',
      },
    },
  },
  mapPinWrapper: {
    position: 'absolute',
    transform: 'translate(-50%, calc(-50% - 33px))',

    '& .MuiTypography-root': {
      maxWidth: pxToRem(236),
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },

    '&.abudhabi-pin': {
      top: '36%',
      left: '68%',
    },
    '&.alain-pin': {
      top: '55%',
      left: '81%',
    },
    '&.aldhafra-pin': {
      top: '59%',
      left: '44%',
    },

    '& button': {
      position: 'relative',
      overflow: 'visible',
      whiteSpace: 'nowrap',
      cursor: 'auto',

      '&:after': {
        // arrow
        content: '""',
        position: 'absolute',
        top: 'calc(100% - 2px)',
        left: '50%',
        transform: 'translateX(-50%)',
        width: 0,
        height: 0,
        borderLeft: '8px solid transparent',
        borderRight: '8px solid transparent',
        borderTop: `9px solid ${theme.palette.common.SeaGreen}`,
      },

      '&:before': {
        // circle benith the arrow
        content: '""',
        position: 'absolute',
        top: 'calc(100% + 12px)',
        left: '50%',
        transform: 'translateX(-50%)',
        width: '8px',
        height: '8px',
        borderRadius: '50%',
        backgroundColor: theme.palette.common.white,
      },
    },

    '& span': {
      color: theme.palette.common.white,
    },

    '& .MuiTab-root': {
      color: 'rgba(255, 255, 255, 1)',
      backgroundColor: theme.palette.common.SeaGreen,
      borderColor: theme.palette.common.SeaGreen,
      padding: '6px 16px',
      borderRadius: '50px',
      border: 0,
      opacity: 1,

      '&:hover': {
        borderColor: theme.palette.common.SeaGreen,
      },
    },
  },
  bannerCTA: {
    marginTop: pxToRem(36),
    [theme.breakpoints.down('md')]: {
      marginTop: pxToRem(24),
    },

    '& .btn-txt': {
      ...theme.palette.common.ellipsis(1),
    },
  },
}));

export default useStyles;