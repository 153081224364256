// tab_inner jss react

import React, { useState } from 'react';
import get from 'lodash/get';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import Typography from '../../../components/atoms/Typography';
import ButtonMUI from '../../../components/atoms/Button';
import Box from '../../../components/molecules/Box';
import Tab from '../../../components/molecules/Tabs/Tab';
import clsx from 'clsx';
import useStyles from './style';

const TabInner = (props) => {
  const index = props.index;
  const data = props.data;
  const map = data.map;
  const { title, description, cta, mapMarkerLabel } = data;
  const svgMap = get(map, 'fields.svgMap.value', '');
  const svgCleaned = svgMap.replace(/\"\g/, '"');
  const classes = useStyles();

  return (
    <div className={classes.tabWrapper}>
      <Box className={classes.tabsFlexbox} display="flex" justifyContent="space-between">
        <div className={classes.tabTextContent}>
          <Typography variant="h4" component="h3" className={classes.tabTitle} animateSettings={{ willAnimate: false }}>
            <Text field={title} />
          </Typography>

          {description && description.value && <Typography variant="body1Regular" component="p" className={classes.tabDescription} animateSettings={{ willAnimate: false }}>
            <Text field={description} />
          </Typography>}

          {cta && cta.value && cta.value.text && (
            <ButtonMUI
              field={cta}
              component="span"
              className={classes.bannerCTA}
              buttonType="whitePillContrast"
              size={'small'}
              hasBorder={false}
            >
              {cta.value.text}
            </ButtonMUI>
          )}
        </div>
        <div className={classes.tabMapWrapperOuter}>
          <div className={classes.tabMapWrapper}>
            <div className='mapContainer' dangerouslySetInnerHTML={{ __html: svgCleaned }} />
            {mapMarkerLabel && mapMarkerLabel.value &&

              <div className={clsx(classes.mapPinWrapper, {
                'abudhabi-pin': index === 0,
                'alain-pin': index === 1,
                'aldhafra-pin': index === 2,
              })}>
                <Tab
                  key={1}
                  label={mapMarkerLabel.value}
                  id={`tab-abudabhi`}
                  aria-controls={`tabpanel-tab-abudabhi`}
                >
                </Tab>

              </div>
            }
          </div>
        </div>
      </Box >
    </div >
  );
}

export default TabInner;